import { FC, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Switch,
  styled,
  ButtonBase,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  alpha,
  FormControlLabel as MuiFormControlLabel
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import Layout from 'components/layout';
import {
  Construction,
  Analytics,
  Language,
  Security,
  Storage,
  Code,
  Save,
  KeyboardArrowRight
} from '@mui/icons-material';

const PageWrapper = styled(Box)`
  min-height: calc(100vh - 64px);
  padding: 32px 0;
`;


const MenuCard = styled(Box)`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px ${alpha('#000', 0.03)};
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentCard = styled(Box)`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px ${alpha('#000', 0.03)};
  overflow: hidden;
`;

const MenuHeader = styled(Box)`
  padding: 24px;
  border-bottom: 1px solid ${alpha('#000', 0.06)};
`;

const MenuTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #1a1f36;
  opacity: 0.4;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const MenuList = styled(Box)`
  padding: 12px;
  flex: 1;
`;

interface MenuItemButtonProps {
  active?: boolean;
}

const MenuItemButton = styled(ButtonBase)<MenuItemButtonProps>`
  width: 100%;
  padding: 14px 16px;
  margin: 4px 0;
  border-radius: 12px;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  color: ${({ active }) => active ? '#ff9f27' : '#1a1f36'};
  background: ${({ active }) => active ? alpha('#ff9f27', 0.08) : 'transparent'};
  transition: all 0.2s ease;

  &:hover {
    background: ${({ active }) => active ? alpha('#ff9f27', 0.12) : alpha('#000', 0.03)};
  }

  .MuiButton-startIcon {
    margin-right: 16px;
    display: flex;
    align-items: center;

    svg {
      font-size: 22px;
      color: ${({ active }) => active ? '#ff9f27' : alpha('#1a1f36', 0.5)};
    }
  }

  .MuiButton-endIcon {
    margin-left: auto;
    display: flex;
    align-items: center;
    opacity: ${({ active }) => active ? 1 : 0};
    transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(-8px)'};
    transition: all 0.2s ease;

    svg {
      font-size: 18px;
      color: #ff9f27;
    }
  }

  &:hover .MuiButton-endIcon {
    opacity: 1;
    transform: translateX(0);
  }
`;

const SaveArea = styled(Box)`
  padding: 24px;
  background: #fff;
  border-top: 1px solid ${alpha('#000', 0.06)};
`;

const SaveButton = styled(Button)<ButtonProps>`
  background: #ff9f27;
  color: white;
  padding: 8px 24px;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  box-shadow: 0 2px 4px ${alpha('#ff9f27', 0.2)};

  &:hover {
    background: ${alpha('#ff9f27', 0.9)};
  }

  svg {
    font-size: 20px;
    margin-right: 8px;
  }
`;

const ContentHeader = styled(Box)`
  padding: 28px 32px;
  border-bottom: 1px solid ${alpha('#000', 0.06)};
`;

const ContentTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #1a1f36;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    font-size: 24px;
    color: #ff9f27;
  }
`;

const ContentBody = styled(Box)`
  padding: 32px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 24px;
  
  & .MuiInputLabel-root {
    font-size: 15px;
    color: ${alpha('#1a1f36', 0.7)};
    
    &.Mui-focused {
      color: #ff9f27;
    }
  }
  
  & .MuiOutlinedInput-root {
    background: #fff;
    border-radius: 12px;
    font-size: 15px;
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${alpha('#ff9f27', 0.5)};
    }
    
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ff9f27;
      border-width: 2px;
    }

    & .MuiSelect-select {
      padding: 14px 16px;
    }
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 24px;
  
  & .MuiInputLabel-root {
    font-size: 15px;
    color: ${alpha('#1a1f36', 0.7)};
    
    &.Mui-focused {
      color: #ff9f27;
    }
  }
  
  & .MuiOutlinedInput-root {
    background: #fff;
    border-radius: 12px;
    font-size: 15px;
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${alpha('#ff9f27', 0.5)};
    }
    
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ff9f27;
      border-width: 2px;
    }

    & input, & textarea {
      padding: 14px 16px;
    }
  }
`;

const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase {
    padding: 12px;
    
    &.Mui-checked {
      color: #ff9f27;
      transform: translateX(20px);
      
      & + .MuiSwitch-track {
        background-color: ${alpha('#ff9f27', 0.4)};
        opacity: 1;
      }
    }
  }
  
  .MuiSwitch-track {
    border-radius: 14px;
    background-color: ${alpha('#1a1f36', 0.1)};
    opacity: 1;
  }

  .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    box-shadow: 0 2px 4px ${alpha('#000', 0.1)};
  }
`;

const StyledFormControlLabel = styled(MuiFormControlLabel)`
  margin: 0 0 24px;
  
  .MuiFormControlLabel-label {
    font-size: 15px;
    color: #1a1f36;
  }
`;

interface SettingOption {
  id: string;
  label: string;
  icon: React.ElementType;
  component: React.ReactNode;
}

const Settings: FC = () => {
  const [activeOption, setActiveOption] = useState<string>('maintenance');
  const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false);

  const settingOptions: SettingOption[] = [
    {
      id: 'maintenance',
      label: 'Maintenance Mode',
      icon: Construction,
      component: (
        <Box>
          <StyledFormControlLabel
            control={
              <StyledSwitch
                checked={maintenanceMode}
                onChange={(e) => setMaintenanceMode(e.target.checked)}
              />
            }
            label="Enable Maintenance Mode"
          />
          <StyledTextField
            fullWidth
            multiline
            rows={4}
            label="Maintenance Message"
            placeholder="Enter the message to display during maintenance..."
            disabled={!maintenanceMode}
          />
        </Box>
      )
    },
    {
      id: 'analytics',
      label: 'Analytics',
      icon: Analytics,
      component: (
        <Box>
          <StyledTextField
            fullWidth
            label="Google Analytics Tracking ID"
            placeholder="UA-XXXXXXXXX-X or G-XXXXXXXXXX"
          />
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Enable Analytics in Development"
          />
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Track User Sessions"
          />
        </Box>
      )
    },
    {
      id: 'localization',
      label: 'Language & Region',
      icon: Language,
      component: (
        <Box>
          <StyledFormControl>
            <InputLabel>Default Language</InputLabel>
            <Select
              label="Default Language"
              defaultValue="en"
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="tr">Turkish</MenuItem>
              <MenuItem value="fr">French</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl>
            <InputLabel>Timezone</InputLabel>
            <Select
              label="Timezone"
              defaultValue="UTC"
            >
              <MenuItem value="UTC">UTC</MenuItem>
              <MenuItem value="GMT">GMT</MenuItem>
              <MenuItem value="EST">EST</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Auto-detect User Language"
          />
        </Box>
      )
    },
    {
      id: 'security',
      label: 'Security',
      icon: Security,
      component: (
        <Box>
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Two-Factor Authentication"
          />
          <StyledTextField
            fullWidth
            label="Session Timeout (minutes)"
            type="number"
            defaultValue={30}
          />
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="SSL Certificate"
          />
        </Box>
      )
    },
    {
      id: 'storage',
      label: 'Storage',
      icon: Storage,
      component: (
        <Box>
          <StyledTextField
            fullWidth
            label="Max Upload Size (MB)"
            type="number"
            defaultValue={10}
          />
          <StyledFormControl>
            <InputLabel>Storage Provider</InputLabel>
            <Select
              label="Storage Provider"
              defaultValue="local"
            >
              <MenuItem value="local">Local Storage</MenuItem>
              <MenuItem value="s3">Amazon S3</MenuItem>
              <MenuItem value="gcs">Google Cloud Storage</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Auto Backup"
          />
        </Box>
      )
    },
    {
      id: 'developer',
      label: 'Developer',
      icon: Code,
      component: (
        <Box>
          <StyledFormControlLabel
            control={<StyledSwitch defaultChecked />}
            label="Debug Mode"
          />
          <StyledTextField
            fullWidth
            label="API Endpoint"
            placeholder="https://api.example.com"
          />
          <StyledTextField
            fullWidth
            label="API Version"
            placeholder="v1"
          />
        </Box>
      )
    }
  ];

  const activeOptionData = settingOptions.find(option => option.id === activeOption);

  return (
    <Layout>
      <PageWrapper>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MenuCard>
                <MenuHeader>
                  <MenuTitle>Settings Menu</MenuTitle>
                </MenuHeader>
                <MenuList>
                  {settingOptions.map((option) => (
                    <MenuItemButton
                      key={option.id}
                      active={activeOption === option.id}
                      onClick={() => setActiveOption(option.id)}
                    >
                      <span className="MuiButton-startIcon">
                        <option.icon />
                      </span>
                      {option.label}
                      <span className="MuiButton-endIcon">
                        <KeyboardArrowRight />
                      </span>
                    </MenuItemButton>
                  ))}
                </MenuList>
                <SaveArea>
                  <SaveButton
                    variant="contained"
                    startIcon={<Save sx={{ color: 'white' }} />}
                  >
                    Save Changes
                  </SaveButton>
                </SaveArea>
              </MenuCard>
            </Grid>

            <Grid item xs={12} md={8}>
              <ContentCard>
                <ContentHeader>
                  <ContentTitle>
                    {activeOptionData && (
                      <>
                        <activeOptionData.icon /> {activeOptionData.label}
                      </>
                    )}
                  </ContentTitle>
                </ContentHeader>
                <ContentBody>
                  {activeOptionData?.component}
                </ContentBody>
              </ContentCard>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
    </Layout>
  );
};

export default Settings; 