import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import loginPageTR from "languages/login-page/tr.json";
import loginPageUS from "languages/login-page/us.json";
import loginPageFR from "languages/login-page/fr.json";

import layoutTR from "languages/layout/tr.json";
import layoutUS from "languages/layout/us.json";
import layoutFR from "languages/layout/fr.json";

import dashboardTR from "languages/dashboard/tr.json";
import dashboardUS from "languages/dashboard/us.json";
import dashboardFR from "languages/dashboard/fr.json";

import cardsTR from "languages/cards/tr.json";
import cardsUS from "languages/cards/us.json";
import cardsFR from "languages/cards/fr.json";

import analyticsTR from "languages/analytics/tr.json";
import analyticsUS from "languages/analytics/us.json";
import analyticsFR from "languages/analytics/fr.json";

import countriesTR from "languages/countries/tr.json";
import countriesUS from "languages/countries/us.json";
import countriesFR from "languages/countries/fr.json";

import profileTR from "languages/profile/tr.json";
import profileUS from "languages/profile/us.json";
import profileFR from "languages/profile/fr.json";

export const resources = {
  tr: {
    countries: countriesTR,
    cards: cardsTR,
    dashboard: dashboardTR,
    layout: layoutTR,
    loginPage: loginPageTR,
    analytics: analyticsTR,
    profile: profileTR
  },
  us: {
    countries: countriesUS,
    cards: cardsUS,
    dashboard: dashboardUS,
    layout: layoutUS,
    loginPage: loginPageUS,
    analytics: analyticsUS,
    profile: profileUS
  },
  fr:{
    countries: countriesFR,
    cards: cardsFR,
    dashboard: dashboardFR,
    layout: layoutFR,
    loginPage: loginPageFR,
    analytics: analyticsFR,
    profile: profileFR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "tr",
    debug: false,
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
