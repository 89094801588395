import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Cards from "./pages/cards";
import { ProtectedLayout } from "./components/layout/ProtectedLayout";
import Categories from "./pages/categories";
import Archives from "./pages/archives";
import Moderators from "./pages/moderators";
import Analytics from "./pages/analytics";
import Users from "./pages/users";
import Clients from "./pages/clients";
import Notifications from "./pages/notifications";
import ActivityLogs from "./pages/activityLogs";
import AddCard from "./pages/cards/components/addCard";
import ShareCard from "./pages/cards/components/shareCard";
import ForgotPassword from "./pages/forgotPassword";
import ChangePassword from "./pages/changePassword";
import Countries from "./pages/countries";
import Profile from "./pages/profile";
import Settings from "./pages/settings";
import { useAppSelector } from "./services";
import Unauthorized from "./components/errors/unauthorized";
import ChangeLogs from "./pages/changeLogs";

function App() {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/changePassword" element={<ChangePassword />} />

      <Route element={<ProtectedLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="cards" element={<Cards />} />
        <Route path="cards/addCard" element={<AddCard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<Settings />} />

        {/* Routes that require "SUPERADMIN" role */}
        {user.role === "SUPERADMIN" ? (
          <>
            <Route path="cards/share" element={<ShareCard />} />
            <Route path="categories" element={<Categories />} />
            <Route path="countries" element={<Countries />} />
            <Route path="archives" element={<Archives />} />
            <Route path="moderators" element={<Moderators />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="users" element={<Users />} />
            <Route path="clients" element={<Clients />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="activityLogs" element={<ActivityLogs />} />
            <Route path="changeLogs" element={<ChangeLogs />} />
          </>
        ) : (
          <Route path="*" element={<Unauthorized />} />
        )}

      </Route>
    </Routes>
  );
}

export default App;
