import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Chip } from '@mui/material';
import SwitchComp from 'components/switchComp';
import { updateCountries } from 'services/countries/api';
import { useAppDispatch } from 'services';
import ReactCountryFlag from "react-country-flag";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const CountryCard = ({ item, setSelectedCountry, setAddCountry }: any) => {
  const dispatch = useAppDispatch();
  const { status } = item;
  const [checked, setChecked] = useState(status === "ACTIVE" ? true : false);

  const handleUpdate = (checked: boolean) => {
    setChecked(checked);
    const newStatus = checked ? "ACTIVE" : "PASSIVE";

    dispatch(
      updateCountries({
        country: {
          ...item,
          status: newStatus,
        },
      })
    );
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedCountry(item);
    setAddCountry(true);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "16px",
        overflow: 'hidden',
        transition: 'all 0.2s ease',
        border: '1px solid',
        borderColor: status === "ACTIVE" ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.05)',
        backgroundColor: status === "ACTIVE" ? '#fff' : '#f8f9fa',
        '&:hover': {
          borderColor: status === "ACTIVE" ? '#3498db' : 'rgba(0,0,0,0.1)',
          transform: 'translateY(-2px)',
          boxShadow: status === "ACTIVE" 
            ? '0 4px 12px rgba(52,152,219,0.1)' 
            : '0 4px 12px rgba(0,0,0,0.05)',
        }
      }}
    >
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                width: 52,
                height: 39,
                overflow: 'hidden',
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                border: '1px solid rgba(0,0,0,0.1)',
                flexShrink: 0,
              }}
            >
              <ReactCountryFlag
                countryCode={item.code}
                svg
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                title={item.name}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: status === "ACTIVE" ? '#2c3e50' : '#95a5a6',
                  fontSize: "16px",
                  fontWeight: 600,
                  mb: 0.5,
                }}
              >
                {item.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <LocationOnOutlinedIcon 
                  sx={{ 
                    fontSize: 16, 
                    color: status === "ACTIVE" ? '#3498db' : '#95a5a6' 
                  }} 
                />
                <Typography
                  sx={{
                    color: status === "ACTIVE" ? '#3498db' : '#95a5a6',
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {item.code.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          </Box>

          <IconButton
            size="small"
            onClick={handleEdit}
            sx={{
              color: status === "ACTIVE" ? '#3498db' : '#95a5a6',
              backgroundColor: status === "ACTIVE" ? 'rgba(52,152,219,0.1)' : 'rgba(0,0,0,0.05)',
              '&:hover': {
                backgroundColor: status === "ACTIVE" ? 'rgba(52,152,219,0.15)' : 'rgba(0,0,0,0.08)',
              }
            }}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: '1px solid',
            borderColor: 'rgba(0,0,0,0.06)',
            pt: 2,
          }}
        >
          <Chip
            label={status === "ACTIVE" ? "Active" : "Passive"}
            size="small"
            sx={{
              backgroundColor: status === "ACTIVE" 
                ? 'rgba(52,152,219,0.1)' 
                : 'rgba(0,0,0,0.05)',
              color: status === "ACTIVE" ? '#3498db' : '#95a5a6',
              fontWeight: 500,
              fontSize: '12px',
              height: '24px',
            }}
          />
          <SwitchComp 
            checked={checked} 
            setChecked={handleUpdate}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default CountryCard;
