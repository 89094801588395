import { FC, ReactElement, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Typography,
  Avatar,
  Skeleton
} from "@mui/material";
import RightArrowIcon from "assets/categories/RightArrowIcon";
import LeftArrowIcon from "assets/categories/LeftArrowIcon";
import UpArrowIcon from "assets/categories/UpArrowIcon";
import { DynamicCard } from "interfaces/dynamic";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { IMG_URL } from "constants/index";

export interface Column<T> {
  id: string;
  label: string | ReactElement<any, any>;
  minWidth?: number | string;
  width?: number | string;
  align?: "right";
  format?: (value: number) => string;
  render?: (item: T, index: number) => any;
}

type CustomAnalyticsTableProps = {
  data: any[];
  setSelectedData?: any;
  isSelected?: boolean;
};
/* 
"cardTitle": "Prof. İlhan: \"Rakamlar 1500'e uzanır diye endişem var. Gelecek hafta maalesef yine binlerin üzerinde seyreder.\"",
            "role": "ADMIN",
            "level": null,
            "count": 7,
            "optionleftstat": 3,
            "optionrightstat": 4,
            "optiontopstat": 0 */
const CustomAnalyticsTable: FC<CustomAnalyticsTableProps> = ({
  data,
  setSelectedData,
  isSelected,
}) => {
  const { t } = useTranslation('analytics');
  const [cardMedia, setCardMedia] = useState<{ [key: string]: string }>({});
  const [apiLoading, setApiLoading] = useState(true);
  const [imageLoadingState, setImageLoadingState] = useState<{ [key: string]: 'loading' | 'loaded' | 'error' }>({});
  const token = JSON.parse(window.localStorage.getItem("token") || "");

  useEffect(() => {
    const fetchCardMedia = async () => {
      setApiLoading(true);
      
      // Reset all image loading states
      const initialLoadingState: { [key: string]: 'loading' | 'loaded' | 'error' } = {};
      data.forEach(item => {
        initialLoadingState[item.cardId] = 'loading';
      });
      setImageLoadingState(initialLoadingState);
      
      const mediaPromises = data.map(async (item) => {
        try {
          const response = await fetch(`https://api.svayp.com/Admin/Cards/GetById/${item.cardId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          });
          const data = await response.json();
          return { cardId: item.cardId, media: data.media };
        } catch (error) {
          console.error('Error fetching card media:', error);
          return { cardId: item.cardId, media: '' };
        }
      });

      const results = await Promise.all(mediaPromises);
      const mediaMap = results.reduce((acc, { cardId, media }) => {
        acc[cardId] = media;
        return acc;
      }, {} as { [key: string]: string });

      setCardMedia(mediaMap);
      setApiLoading(false);
    };

    fetchCardMedia();
  }, [data, token]);

  // Function to handle successful image load
  const handleImageLoad = (cardId: string) => {
    setTimeout(() => {
      setImageLoadingState(prev => ({
        ...prev,
        [cardId]: 'loaded'
      }));
    }, 500); // Short delay to ensure smooth transition
  };

  // Function to handle image load error
  const handleImageError = (cardId: string) => {
    setImageLoadingState(prev => ({
      ...prev,
      [cardId]: 'error'
    }));
  };

  // Function to preload image and return a promise
  const preloadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => reject();
    });
  };

  const TinyTableCell = (value: number | string, index: number) => {
    return (
      <TableCell
        key={Math.random() + index}
        sx={{
          width: "100%",
          borderWidth: "1.5px",
          textAlign: "center",
          cursor: "pointer",
          color: value === t('anonymous') || value === t('verified') || value === t('advanced') ? "#fff" : "#000",
          backgroundColor:
          value === t('anonymous')
            ? "#000"
            : value === t('verified')
            ? "#34c759"
            : value === t('advanced')
            ? "#0076FF"
            : null,
        }}
      >
        {value}
      </TableCell>
    );
  };
  // console.log("data", data);
  const columns: Column<DynamicCard>[] = [
    {
      id: "image",
      label: "",
      width: "8%",
      render: (item) => {
        const imageUrl = IMG_URL + (cardMedia[item.cardId] || '');
        const isLoading = apiLoading || imageLoadingState[item.cardId] !== 'loaded';
        
        if (imageUrl && imageLoadingState[item.cardId] === 'loading') {
          preloadImage(imageUrl)
            .then(() => handleImageLoad(item.cardId))
            .catch(() => handleImageError(item.cardId));
        }
        
        return (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            gap: 1,
            p: 1 
          }}>
            {isLoading ? (
              <>
                <Skeleton 
                  variant="circular" 
                  width={60} 
                  height={60} 
                  animation="wave"
                />
                <Skeleton 
                  variant="text"
                  width={70}
                  height={20}
                  animation="wave"
                />
              </>
            ) : (
              <>
                <Avatar
                  src={imageUrl}
                  alt={item.cardTitle}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: "50%",
                  }}
                />
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '12px'
                  }}
                >
                  {localStorage.getItem('i18nextLng') === 'tr' 
                    ? moment(item?.createdAt).format('DD.MM.YYYY') 
                    : moment(item?.createdAt).format('MM.DD.YYYY')
                  }
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },
    {
      id: "title",
      label: "TITLE",
      width: "30%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.cardTitle}
        </Typography>
      ),
    },
    {
      id: "userType",
      label: "USER TYPE",
      width: "15%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {[t('anonymous'), t('verified'), t('advanced')].map(
            TinyTableCell
          )}
        </Box>
      ),
    },
    {
      id: "count",
      label: "TOTAL VOTE",
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x) => {
              const totalCount = item.dynamics.reduce((prev, i) => {
                return prev + i.count;
              }, 0);
              return `${((x.count / totalCount) * 100).toFixed(1)}% (${
                x.count
              })`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
    {
      id: "positive",
      label: t('positive'),
      width: "10%",
      render: (item) => {
        // console.log(
        //   item.dynamics.reduce((prev, curr) => prev + curr.optionleftstat, 0)
        // );
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {item.dynamics
              .map((x, index) => {
                const indexDynamic = item.dynamics[index];
                const { optionrightstat, optionleftstat, optiontopstat } =
                  indexDynamic;
                const totalCount =
                  optionleftstat + optiontopstat + optionrightstat;
                // console.log("totalCount", totalCount);
                return `${((x.optionleftstat / totalCount) * 100 || 0).toFixed(
                  1
                )}% (${x.optionleftstat})`;
              })
              .map(TinyTableCell)}
          </Box>
        );
      },
    },
    {
      id: "negative",
      label: t('negative'),
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x, index) => {
              const indexDynamic = item.dynamics[index];
              const { optionrightstat, optionleftstat, optiontopstat } =
                indexDynamic;
              const totalCount =
                optionleftstat + optiontopstat + optionrightstat;
              // console.log("totalCount", totalCount);
              return `${((x.optionrightstat / totalCount) * 100 || 0).toFixed(
                1
              )}% (${x.optionrightstat})`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
    {
      id: "indecisive",
      label: t('neutral'),
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x, index) => {
              const indexDynamic = item.dynamics[index];
              const { optionrightstat, optionleftstat, optiontopstat } =
                indexDynamic;
              const totalCount =
                optionleftstat + optiontopstat + optionrightstat;
              return `${((x.optiontopstat / totalCount) * 100 || 0).toFixed(
                1
              )}% (${x.optiontopstat})`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
  ];
  return (
    <TableContainer sx={{ background: "#fff", mb: "0px" }} component={Box}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column, _idx) => (
              <TableCell
                key={Math.random() + column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width,
                  paddingLeft: _idx === 0 ? "24px" : "0px",
                  textAlign: _idx === 0 ? "start" : "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  {column.id === "positive" && <RightArrowIcon color="#000" />}
                  {column.id === "negative" && <LeftArrowIcon color="#000" />}
                  {column.id === "indecisive" && <UpArrowIcon color="#000" />}
                  {column.label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, idx: number) => {
            const isRowSelected = isSelected && setSelectedData && row.id === (setSelectedData.id || -1);
            return (
              <TableRow 
                key={row?.id}
                onClick={() => {
                  // If we're already viewing details and click the same row, close details
                  if (isSelected && setSelectedData && row.id === setSelectedData.id) {
                    setSelectedData(null);
                  } else if (!isSelected) {
                    // Otherwise, if no row is selected, select this one
                    setSelectedData(row);
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: isRowSelected ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)'
                  }
                }}
              >
                {columns.map((column, idx: number) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={Math.random() + column.id}
                      align={column.align}
                      style={{
                        textAlign: "center",
                        padding: "0px",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      {column.render ? column.render(row, idx) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomAnalyticsTable;
