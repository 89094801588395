import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const KidsCountStats = ({ analyticsData }: any) => {
  const { t } = useTranslation('analytics');
  const [kidsCountData, setKidsCountData] = useState<number[]>([]);

  useEffect(() => {
    const getKidsCountStats = () => {
      const stats: number[] = [];
      for (let i = 0; i <= 4; i++) {
        stats.push(
          analyticsData
            ?.filter((item: any) => item.kids === i)
            .map((item: any) => item.count) || 0
        );
      }
      setKidsCountData(stats);
    };
    getKidsCountStats();
  }, [analyticsData]);

  const data = kidsCountData
    .filter((count: number) => count > 0)
    .map((count: number, index: number) => ({
      id: index + 1,
      label: (index + 1).toString(),
      value: count,
      color:
        index === 0
          ? "#c5ae68"
          : index === 1
          ? "#7fc568"
          : index === 2
          ? "#68b9c5"
          : "#c56868",
    }));

  const noData = [
    {
      id: "noData",
      label: "NoData",
      value: 1,
      color: "#dcdcdc",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>{t("child-count-distribution")}</Typography>
        </Box>
        <Box
          p={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "270px",
          }}
        >
          {data.length > 0 ? (
            <ResponsivePie
              data={data}
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.05}
              padAngle={2}
              cornerRadius={3}
              colors={{ datum: "data.color" }}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              arcLabelsTextColor="#ffffff"
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 20,
                  itemsSpacing: 10,
                  itemWidth: 40,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <ResponsivePie
              data={noData}
              arcLabelsTextColor="#ffffff"
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.5}
              padAngle={1.3}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsTextColor="#333333"
              colors={{ datum: "data.color" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default KidsCountStats;
