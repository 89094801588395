import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";

const VoteWeekStats = ({ selectedData }: any) => {
  const { t } = useTranslation('analytics');
  const [data, setData] = useState<any>([]);
  const [lastWeekDates, setLastWeekDates] = useState<any>([]);

  const days = [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ];
  const labels = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    const dayOfWeek = date.getDay();
    const label = days[dayOfWeek];
    // labels.push(label + " " + date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear());
    labels.push(label);
  }

  useEffect(() => {
    async function fetchData() {
      const token = JSON.parse(window.localStorage.getItem("token") || "");
      const oneWeekAgo = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      const formattedDates = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(oneWeekAgo.getTime() + i * 24 * 60 * 60 * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}T00:00:00`;
      });
      setLastWeekDates(formattedDates);
      const response = await fetch(
        "https://api.svayp.com/Admin/VotesView/GetDynamic",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            Columns: [
              { Name: "role" },
              { Name: "level" },
              { Name: "voteDate" },
              {
                Name: "optionLeftStat",
                AggregateFunction: "sum",
                Alias: "leftVotes",
              },
              {
                Name: "optionRightStat",
                AggregateFunction: "sum",
                Alias: "rightVotes",
              },
              {
                Name: "optionTopStat",
                AggregateFunction: "sum",
                Alias: "topVotes",
              },
              { Name: "id", AggregateFunction: "count", Alias: "count" },
            ],
            Where: {
              TYPE: "AND",
              ITEMS: [
                {
                  TYPE: "OR",
                  ITEMS: formattedDates.map((date) => ({
                    Name: "voteDate",
                    Value: date,
                    Type: "=",
                  })),
                },
                { NAME: "cardId", VALUE: selectedData?.cardId },
              ],
            },
          }),
        }
      );
      const data = await response.json();
      setData(data.data);
    }
    fetchData();
  }, [selectedData]);

  const userDataByLevel: Record<string, UserDataType[]> = {
    ANON: [],
    USER: [],
  };

  data.forEach((item: any) => {
    if ((item.level === 0, item.role === "ANON")) {
      userDataByLevel.ANON.push({
        level: 0,
        role: item.role,
        voteDate: item.voteDate,
        count: item.count,
      });
    } else if ((item.level === 1 || item.level === 2, item.role === "USER")) {
      userDataByLevel.USER.push({
        level: item.level,
        role: item.role,
        voteDate: item.voteDate,
        count: item.count,
      });
    }
  });

  type TotalCountsByDateType = Record<string, number>;

  interface UserDataType {
    level: number;
    role: string;
    voteDate: string;
    count: number;
  }

  function getTotalCountsByDate(
    userData: UserDataType[],
    lastWeekDates: string[]
  ): TotalCountsByDateType {
    const totalCountsByDate: TotalCountsByDateType = {};

    lastWeekDates.forEach((date: string) => {
      const totalCount = userData
        .filter((item: UserDataType) => item.voteDate === date)
        .reduce((a: number, b: UserDataType) => a + b.count, 0);
      totalCountsByDate[date] = totalCount;
    });

    return totalCountsByDate;
  }

  const anonTotalCountsByDate = getTotalCountsByDate(
    userDataByLevel.ANON,
    lastWeekDates
  );
  const confirmedTotalCountsByDate = getTotalCountsByDate(
    userDataByLevel.USER.filter(
      (item: UserDataType) => item.role === "USER" && item.level === 1
    ),
    lastWeekDates
  );
  const enhancedTotalCountsByDate = getTotalCountsByDate(
    userDataByLevel.USER.filter(
      (item: UserDataType) => item.role === "USER" && item.level === 2
    ),
    lastWeekDates
  );

  const weekData = labels.map((label, index) => ({
    country: label,
    anon: anonTotalCountsByDate[lastWeekDates[6 - index]],
    confirmed: confirmedTotalCountsByDate[lastWeekDates[6 - index]],
    enhanced: enhancedTotalCountsByDate[lastWeekDates[6 - index]],
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>{t("last-week-distribution")}</Typography>
        </Box>
        <Box
          p={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "270px",
          }}
        >
          <ResponsiveBar
            data={weekData}
            keys={["anon", "confirmed", "enhanced"]}
            indexBy="country"
            colors={["#21252D", "#34C759", "#0076FF"]}
            margin={{ top: 40, right: 10, bottom: 20, left: 35 }}
            padding={0.2}
            layout="vertical"
            minValue={0}
            enableGridX={true}
            enableGridY={true}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            axisBottom={null}
            // axisBottom={{
            //     tickSize: 5,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     legend: 'OY SAYISI',
            //     legendPosition: 'middle',
            //     legendOffset: 32
            // }}
            // axisLeft={{
            //     tickSize: 5,
            //     tickPadding: 5,
            //     tickRotation: 0,
            //     legend: 'GÜNLER',
            //     legendPosition: 'middle',
            //     legendOffset: -40
            // }}
            axisTop={{}}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={"#ffffff"}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateY: 22,
                translateX: -20,
                itemsSpacing: 40,
                itemWidth: 40,
                itemHeight: 20,
                itemOpacity: 0.85,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            fill={[
              {
                match: {
                  id: "anon",
                },
                id: "dots",
              },
              {
                match: {
                  id: "enhanced",
                },
                id: "lines",
              },
            ]}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#1e1e1e",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#1b7ded",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VoteWeekStats;
