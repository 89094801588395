import { 
  Box, 
  Card, 
  CardContent, 
  Grid, 
  Typography, 
  TextField, 
  Button, 
  Avatar,
  IconButton,
  Badge,
  Stack,
  Container,
  useTheme,
  alpha,
  InputAdornment,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'services';
import { PhotoCamera, Edit, Phone, Email, Person, Lock, Save } from '@mui/icons-material';
import Layout from 'components/layout';

interface CustomTextFieldProps {
  icon: React.ElementType;
  [key: string]: any;
}

const PRIMARY_COLOR = '#ff9f27';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: alpha('#f8fafc', 0.8),
    transition: 'all 0.2s ease-in-out',
    '&.Mui-disabled': {
      backgroundColor: alpha('#f8fafc', 0.8),
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(PRIMARY_COLOR, 0.1),
      },
      '& input': {
        color: theme.palette.text.primary,
        WebkitTextFillColor: 'unset',
        opacity: 0.8,
      }
    },
    '&:hover': {
      backgroundColor: alpha(PRIMARY_COLOR, 0.02),
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: PRIMARY_COLOR,
      }
    },
    '&.Mui-focused': {
      backgroundColor: alpha(PRIMARY_COLOR, 0.02),
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: PRIMARY_COLOR,
      }
    }
  },
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: PRIMARY_COLOR,
    },
    '&.Mui-disabled': {
      color: theme.palette.text.secondary,
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: alpha(PRIMARY_COLOR, 0.1),
  }
}));

const Profile = () => {
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (user) {
      setFormData(prev => ({
        ...prev,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phone: user.phone || '',
        role: user.role || ''
      }));
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    // API call will be added here
    setTimeout(() => setLoading(false), 1000);
  };

  const CustomTextField = ({ icon: Icon, ...props }: CustomTextFieldProps) => (
    <StyledTextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon sx={{ color: alpha(PRIMARY_COLOR, 0.7) }} />
          </InputAdornment>
        ),
      }}
    />
  );

  const ProfileContent = () => (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Left Column - Personal Info */}
        <Grid item xs={12} md={4}>
          <Card sx={{ 
            height: '100%',
            background: 'linear-gradient(135deg, #fff 0%, #f8fafc 100%)',
            borderRadius: 3,
            border: '1px solid',
            borderColor: alpha(PRIMARY_COLOR, 0.1),
            boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
          }}>
            <CardContent sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              textAlign: 'center',
              py: 6
            }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <IconButton 
                    sx={{ 
                      width: 40,
                      height: 40,
                      bgcolor: PRIMARY_COLOR,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': { 
                        bgcolor: alpha(PRIMARY_COLOR, 0.8),
                        transform: 'translateY(-2px)',
                      }
                    }}
                  >
                    <PhotoCamera sx={{ color: 'white', fontSize: 20 }} />
                  </IconButton>
                }
              >
                <Avatar 
                  sx={{ 
                    width: 140, 
                    height: 140,
                    mb: 2,
                    bgcolor: PRIMARY_COLOR,
                    fontSize: '3rem',
                    fontWeight: 600,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                  }}
                >
                  {formData.firstName?.[0]}{formData.lastName?.[0]}
                </Avatar>
              </Badge>
              
              <Typography variant="h5" sx={{ 
                color: theme.palette.text.primary, 
                fontWeight: 600, 
                mt: 3,
                mb: 0.5
              }}>
                {formData.firstName} {formData.lastName}
              </Typography>
              
              <Typography 
                variant="body1" 
                sx={{ 
                  color: alpha(theme.palette.text.primary, 0.6),
                  mb: 3
                }}
              >
                {formData.email}
              </Typography>

              <Box sx={{ 
                bgcolor: alpha(PRIMARY_COLOR, 0.05),
                px: 3,
                py: 1.5,
                borderRadius: 3,
                border: '1px solid',
                borderColor: alpha(PRIMARY_COLOR, 0.1)
              }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: PRIMARY_COLOR,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px'
                  }}
                >
                  {formData.role}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Column - Forms */}
        <Grid item xs={12} md={8}>
          <Stack spacing={4}>
            {/* Personal Information */}
            <Card sx={{ 
              background: 'linear-gradient(135deg, #fff 0%, #f8fafc 100%)',
              borderRadius: 3,
              border: '1px solid',
              borderColor: alpha(PRIMARY_COLOR, 0.1),
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
            }}>
              <CardContent sx={{ p: 4 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',
                  mb: 4
                }}>
                  <Typography variant="h6" sx={{ 
                    color: theme.palette.text.primary, 
                    fontWeight: 600 
                  }}>
                    {t('personal_info.title')}
                  </Typography>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: PRIMARY_COLOR,
                      bgcolor: alpha(PRIMARY_COLOR, 0.05),
                      '&:hover': {
                        bgcolor: alpha(PRIMARY_COLOR, 0.1)
                      }
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Person}
                      fullWidth
                      name="firstName"
                      label={t('personal_info.name')}
                      variant="outlined"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Person}
                      fullWidth
                      name="lastName"
                      label={t('personal_info.surname')}
                      variant="outlined"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Email}
                      fullWidth
                      name="email"
                      label={t('personal_info.email')}
                      variant="outlined"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Phone}
                      fullWidth
                      name="phone"
                      label={t('personal_info.phone')}
                      variant="outlined"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Security */}
            <Card sx={{ 
              background: 'linear-gradient(135deg, #fff 0%, #f8fafc 100%)',
              borderRadius: 3,
              border: '1px solid',
              borderColor: alpha(PRIMARY_COLOR, 0.1),
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
            }}>
              <CardContent sx={{ p: 4 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',
                  mb: 4
                }}>
                  <Typography variant="h6" sx={{ 
                    color: theme.palette.text.primary, 
                    fontWeight: 600 
                  }}>
                    {t('security.title')}
                  </Typography>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: PRIMARY_COLOR,
                      bgcolor: alpha(PRIMARY_COLOR, 0.05),
                      '&:hover': {
                        bgcolor: alpha(PRIMARY_COLOR, 0.1)
                      }
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CustomTextField
                      disabled
                      icon={Lock}
                      fullWidth
                      name="currentPassword"
                      label={t('security.current_password')}
                      variant="outlined"
                      type="password"
                      value={formData.currentPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Lock}
                      fullWidth
                      name="newPassword"
                      label={t('security.new_password')}
                      variant="outlined"
                      type="password"
                      value={formData.newPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      disabled
                      icon={Lock}
                      fullWidth
                      name="confirmPassword"
                      label={t('security.confirm_password')}
                      variant="outlined"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 4,
          pt: 3,
          borderTop: '1px solid',
          borderColor: alpha(PRIMARY_COLOR, 0.1),
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: alpha(theme.palette.text.primary, 0.1),
            color: theme.palette.text.secondary,
            px: 4,
            '&:hover': {
              borderColor: theme.palette.text.secondary,
              backgroundColor: alpha(theme.palette.text.primary, 0.02),
            }
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSave}
          startIcon={<Save sx={{ color: 'white' }} />}
          sx={{
            px: 4,
            bgcolor: PRIMARY_COLOR,
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            '&:hover': {
              bgcolor: alpha(PRIMARY_COLOR, 0.8),
            },
            '&.Mui-disabled': {
              bgcolor: alpha(PRIMARY_COLOR, 0.5),
            }
          }}
        >
          {t('buttons.save')}
        </LoadingButton>
      </Box>
    </Container>
  );

  return (
    <Layout>
      <ProfileContent />
    </Layout>
  );
};

export default Profile; 