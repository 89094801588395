import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "services";

const TotalUserTypeStats = ({ selectedData }: any) => {
  const { t } = useTranslation('analytics');
  const cards = useAppSelector((state) => state.analytics.cards);

  const allVoteData: Array<number> =
    cards
      .find((card) => card.cardId === selectedData?.cardId)
      ?.dynamics.map((x) => x.count) ?? [];

  const data = [
    {
      id: "anonim",
      label: t("anonymous"),
      value: allVoteData[0] || 0,
      color: "#21252D",
    },
    {
      id: "onaylı",
      label: t("verified"),
      value: allVoteData[1] || 0,
      color: "#34C759",
    },
    {
      id: "gelismis",
      label: t("advanced"),
      value: allVoteData[2] || 0,
      color: "#0076FF",
    },
  ];

  const filteredData = data.filter((item) => item.value > 0);

  const noData = [
    {
      id: "noData",
      label: "NoData",
      value: 1,
      color: "#dcdcdc",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>{t("total-member-distribution")}</Typography>
        </Box>
        <Box
          p={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "270px",
          }}
        >
          {filteredData.length > 0 ? (
            <ResponsivePie
              data={filteredData}
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.05}
              padAngle={2}
              cornerRadius={3}
              colors={{ datum: "data.color" }}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              arcLabelsTextColor="#ffffff"
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: -12,
                  translateY: 20,
                  itemsSpacing: 40,
                  itemWidth: 40,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <ResponsivePie
              data={noData}
              arcLabelsTextColor="#ffffff"
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.5}
              padAngle={1.3}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsTextColor="#333333"
              colors={{ datum: "data.color" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TotalUserTypeStats;
