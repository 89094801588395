import {
  Box,
  Modal,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CloseIcon from "assets/categories/CloseIcon";
import SwitchComp from "components/switchComp";
import { useAppDispatch, useAppSelector } from "services";
import { Country } from "interfaces/country";
import {
  createCountries,
  deleteCountries,
  updateCountries,
} from "services/countries/api";
import TimezoneSelect from "../timeZoneSelect";
import { LoadingButton } from "@mui/lab";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

type CountryModalProps = {
  open: boolean;
  onClose: any;
  country?: Country;
};

const CountryModal: FC<CountryModalProps> = ({ open, onClose, country }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.countries);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation('countries');

  const [countryCode, setCountryCode] = useState(country?.code || "");
  const [countryName, setCountryName] = useState(country?.name);
  const [countryId, setCountryId] = useState(country?.id);

  const [selectedTimezone, setSelectedTimezone] = useState('0');


  useEffect(() => {
    setCountryName(country?.name);
    setCountryCode(country?.code || "");
    setCountryId(country?.id);
    setChecked(country?.status === "ACTIVE");
  }, [country]);

  const deleteCountry = async () => {
    const confirmed = window.confirm(
      "Bu işlemi geri alamazsınız silmek istediğinizden emin misiniz?"
    );
    if (confirmed && country) {
      dispatch(
        deleteCountries({
          countryId: countryId || country.id,
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    }
  };

  const saveCountry = async () => {
    if (country) {
      dispatch(
        updateCountries({
          country: {
            id: country.id,
            name: countryName!,
            code: countryCode!,
            status: checked ? "ACTIVE" : "PASSIVE",
          },
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    } else {
      dispatch(
        createCountries({
          country: {
            name: countryName!,
            code: countryCode!,
            status: checked ? "ACTIVE" : "PASSIVE",
          },
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    }
  };

  return (
    <Modal 
      open={open} 
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Box
        sx={{
          width: "560px",
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
          outline: 'none',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: '1px solid',
            borderColor: 'rgba(0,0,0,0.08)',
            backgroundColor: 'rgba(0,0,0,0.02)',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              color: '#2c3e50',
              fontSize: '1.25rem',
            }}
          >
            {country ? t('modal.edit_country') : t('modal.add_country')}
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: '#95a5a6',
              '&:hover': { 
                color: '#7f8c8d',
                backgroundColor: 'rgba(0,0,0,0.04)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#7f8c8d',
                mb: 1,
                lineHeight: 1.5,
              }}
            >
              {t('modal.iso_code_info')}{" "}
              <Box
                component="a"
                href="https://apps.timwhitlock.info/emoji/tables/iso3166"
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: "#3498db",
                  textDecoration: "none",
                  fontWeight: 500,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {t('modal.iso_code_link')}
              </Box>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField
              fullWidth
              label={t('modal.country_name')}
              variant="outlined"
              size="small"
              value={countryName}
              onChange={(e) => setCountryName(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#3498db',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#3498db',
                  }
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: '#3498db',
                  }
                }
              }}
            />
            <TextField
              label={t('modal.country_code')}
              variant="outlined"
              size="small"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              sx={{
                width: '30%',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#3498db',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#3498db',
                  }
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: '#3498db',
                  }
                }
              }}
            />
          </Box>

          {countryCode && (
            <Box 
              sx={{ 
                mb: 3,
                p: 2.5,
                backgroundColor: 'rgba(52,152,219,0.05)',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                border: '1px solid',
                borderColor: 'rgba(52,152,219,0.1)',
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 36,
                  overflow: 'hidden',
                  borderRadius: 1.5,
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fff',
                  border: '1px solid rgba(0,0,0,0.08)',
                }}
              >
                <ReactCountryFlag
                  countryCode={countryCode}
                  svg
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
              <Typography sx={{ 
                color: '#2c3e50', 
                fontSize: '14px',
                fontWeight: 500,
              }}>
                {t('modal.flag_preview')}
              </Typography>
            </Box>
          )}

          <Box sx={{ mb: 3 }}>
            <TimezoneSelect 
              selectedTimezone={selectedTimezone} 
              setSelectedTimezone={setSelectedTimezone} 
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: 'rgba(0,0,0,0.02)',
              borderRadius: 2,
              p: 2.5,
              border: '1px solid',
              borderColor: 'rgba(0,0,0,0.04)',
            }}
          >
            <Typography variant="body2" sx={{ 
              color: '#2c3e50',
              fontWeight: 500,
            }}>
              {t('status')}: <strong>{checked ? t('active') : t('passive')}</strong>
            </Typography>
            <SwitchComp checked={checked} setChecked={setChecked} />
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            p: 3,
            pt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: '1px solid',
            borderColor: 'rgba(0,0,0,0.08)',
            backgroundColor: 'rgba(0,0,0,0.01)',
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
          }}
        >
          {country && (
            <Button
              variant="outlined"
              color="error"
              onClick={deleteCountry}
              startIcon={<DeleteOutlineIcon />}
              sx={{ 
                borderColor: 'rgba(231,76,60,0.5)',
                color: '#e74c3c',
                '&:hover': {
                  borderColor: '#e74c3c',
                  backgroundColor: 'rgba(231,76,60,0.05)',
                }
              }}
            >
              {t('modal.delete_country')}
            </Button>
          )}
          <Box sx={{ display: 'flex', gap: 2, ml: 'auto' }}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                borderColor: 'rgba(0,0,0,0.1)',
                color: '#7f8c8d',
                '&:hover': {
                  borderColor: '#7f8c8d',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }
              }}
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={saveCountry}
              sx={{
                bgcolor: '#3498db',
                '&:hover': {
                  bgcolor: '#2980b9',
                },
                '&.Mui-disabled': {
                  bgcolor: 'rgba(52,152,219,0.5)',
                }
              }}
            >
              {country ? t('update') : t('save')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CountryModal;
