import { Box, styled } from "@mui/material";

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 120px;
`;

export const CardsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
  padding: 0px 32px;
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    padding: 0 16px 16px;
  }
`;
