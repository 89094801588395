import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import LeftArrowIcon from "assets/categories/LeftArrowIcon";
import RightArrowIcon from "assets/categories/RightArrowIcon";
import UpArrowIcon from "assets/categories/UpArrowIcon";
import SwitchComp from "components/switchComp";
import { IMG_URL } from "constants/index";
//@ts-ignore
import { ColorExtractor } from "react-color-extractor";
import { Wrapper } from "./styles";
import { useAppDispatch } from "services";
import { updateCategories } from "services/categoriesSlice";
import { Category } from "interfaces/category";
import { useTranslation } from "react-i18next";
type CategoryCardProps = {
  item: Category;
  onClick?: any;
};

const CategoryCard: FC<CategoryCardProps> = ({ item, onClick }) => {
  const { t } = useTranslation('analytics');
  const dispatch = useAppDispatch();
  const { title, status } = item;
  const [checked, setChecked] = useState(status === "ACTIVE" ? true : false);
  const [bgColor, setBgColor] = useState("#00000080");

  const handleUpdate = (checked: boolean) => {
    setChecked(checked);
    dispatch(
      updateCategories({
        category: {
          ...item,
          status: checked ? "ACTIVE" : "PASSIVE",
        },
      })
    );
  };
console.log('card', item)
  return (
    <Box
      sx={{
        position: "relative",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 100,
          right: "16px",
          top: "16px",
        }}
      >
        <SwitchComp checked={checked} setChecked={handleUpdate} />
      </Box>

      {!checked && (
        <Wrapper
          sx={{ position: "absolute", bgcolor: "#ffffff99", zIndex: 10 }}
        />
      )}

      <Wrapper sx={{ bgcolor: bgColor, color: "white" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <ColorExtractor getColors={(colors: any) => setBgColor(colors[2])}>
            <img
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={IMG_URL + item.media}
              alt="category-icon"
            />
          </ColorExtractor>
          <Typography variant="cardNumber" sx={{ fontWeight: "400", flex: 1 }}>
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "54px",
            marginTop: "16px",
          }}
        >
          <Typography variant="cardContent">
            {item.totalCount} {t('related-card')}  ({item.releasingCount} {t('active')}  -{" "}
            {item.totalCount - item.releasingCount} {t('passive')} )
          </Typography>
          <Typography variant="cardContent">11.06.2022</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: "54px",
            marginTop: "4px",
          }}
        >
          <Typography variant="cardContent">
            {item.defaultCardPoint}{" "}
            {item.defaultCardPoint > 1 ? "points" : "point"}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            background: "#5A5A5A80",
          }}
        >
          <Box
            sx={{
              padding: "6px 20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <UpArrowIcon />
              <Typography variant="cardContent">
                {item.optionTopStat}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <RightArrowIcon />
              <Typography variant="cardContent">
                {item.optionRightStat}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <LeftArrowIcon />
              <Typography variant="cardContent">
                {" "}
                {item.optionLeftStat}
              </Typography>
            </Box>
            <Typography variant="cardContent">/vote</Typography>
          </Box>
          <Box
            sx={{
              background: "#c2c2c2",
              height: "32px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 20px",
            }}
          >
            <Typography variant="cardContent">
              This week: +{item.lastWeekVoteCount || 0}
            </Typography>
            <Typography variant="cardContent">
              This month: +{item.lastMonthVoteCount || 0}
            </Typography>
            <Typography variant="cardContent">
              This year: +{item.lastYearVoteCount || 0}
            </Typography>
            <Typography variant="cardContent">/users</Typography>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default CategoryCard;
