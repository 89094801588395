import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface VotesData {
  [key: string]: {
    leftvotes: number;
    rightvotes: number;
    topvotes: number;
  }[];
}

const BirthDateStats = ({ analyticsData }: any) => {
  const { t } = useTranslation('analytics');
  const voteRanges: { [key: string]: number[] } = {
    "18_24": [18, 24],
    "25_34": [25, 34],
    "35_44": [35, 44],
    "45_54": [45, 54],
    "55_64": [55, 64],
    "65Plus": [65, Infinity],
  };

  const [totalVotesCount, setTotalVotesCount] = useState<{
    [key: string]: { leftVotes: number; rightVotes: number; topVotes: number };
  }>({
    ...Object.fromEntries(
      Object.keys(voteRanges).map((key) => [
        key,
        { leftVotes: 0, rightVotes: 0, topVotes: 0 },
      ])
    ),
  });

  useEffect(() => {
    const votesData: VotesData = Object.entries(voteRanges).reduce(
      (accumulator: any, [key, [minAge, maxAge]]) => {
        const votesInRange = analyticsData?.filter(
          ({ birthDay, level }: any) => {
            const date = new Date(birthDay);
            const userAge = new Date().getFullYear() - date.getFullYear();
            return (
              userAge >= minAge &&
              userAge <= maxAge &&
              birthDay !== null &&
              level !== 0
            );
          }
        );
        accumulator[key] = votesInRange || [];
        return accumulator;
      },
      {}
    );

    const totalVotesData = Object.entries(votesData).reduce(
      (accumulator: any, [key, votesInRange]) => {
        const leftVotes = votesInRange.filter(
          ({ leftvotes }) => leftvotes !== 0
        ).length;
        const rightVotes = votesInRange.filter(
          ({ rightvotes }) => rightvotes !== 0
        ).length;
        const topVotes = votesInRange.filter(
          ({ topvotes }) => topvotes !== 0
        ).length;

        accumulator[key] = { leftVotes, rightVotes, topVotes };
        return accumulator;
      },
      {}
    );

    setTotalVotesCount(totalVotesData);
    // eslint-disable-next-line
  }, [analyticsData]);

  const data = Object.entries(voteRanges).map(([key, [minAge, maxAge]]) => ({
    ageTitle: `${minAge}-${maxAge}`,
    leftVote: totalVotesCount[key].leftVotes,
    rightVote: totalVotesCount[key].rightVotes,
    topVote: totalVotesCount[key].topVotes,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>{t("age-range")}</Typography>
        </Box>
        <Box
          p={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "270px",
          }}
        >
          <ResponsiveBar
            data={data}
            keys={["leftVote", "rightVote", "topVote"]}
            indexBy="ageTitle"
            margin={{ top: 40, right: 10, bottom: 20, left: 35 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={["#ed0405", "#3bc759", "#999"]}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={{}}
            axisBottom={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={"#ffffff"}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateY: 22,
                translateX: -20,
                itemsSpacing: 40,
                itemWidth: 40,
                itemHeight: 20,
                itemOpacity: 0.85,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BirthDateStats;
